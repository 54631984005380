import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const PasswordInput = ({
  control,
  className,
  name,
  placeholder,
  show,
  required,
  readOnly,
  matchTo,
  noValidations,
}) => {
  const validations = !matchTo
    ? {
        notMeet: (v) => {
          let validChars = true
          for (let c of v) {
            if (!c.match(/[a-zA-Z0-9!@#$%^&*()_+\-={}<>,.|"'~`:;\\?/[\]]/)) {
              validChars = false
              break
            }
          }
          const isValid = validChars && v.length >= 8 && v.length <= 20
          return isValid || 'Password does not meet requirements'
        },
      }
    : { notMatch: (v) => v === matchTo || "Passwords didn't match. Try again." }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <input
          id={name}
          type={show ? 'text' : 'password'}
          className={'form-control' + (className ? ' ' + className : '')}
          placeholder={placeholder}
          maxLength={20}
          readOnly={readOnly}
          value={value || ''}
          onChange={onChange}
          ref={ref}
          autoComplete="true"
        />
      )}
      rules={{
        required: required
          ? required === true
            ? `Please enter ${placeholder?.toLowerCase()}`
            : required
          : null,
        validate: !noValidations ? validations : null,
      }}
    />
  )
}

PasswordInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  matchTo: PropTypes.string,
  noValidations: PropTypes.bool,
}

export default PasswordInput
