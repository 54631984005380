import * as types from './actionTypes'

export function loginSuccess(user, accessToken, redirect) {
  return { type: types.LOGIN, user: user, accessToken: accessToken, redirect: redirect }
}

export function logoutSuccess() {
  return { type: types.LOGOUT }
}

export function updateProfileSuccess(user) {
  return { type: types.UPDATE_USER, user: user }
}
