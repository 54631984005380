import * as types from '../actions/actionTypes'

export default function authReducer(state, action) {
  switch (action.type) {
    case types.SET_REGIONS:
      return { ...state, regions: action.regions }
    case types.SET_BATCH_YEAR:
      localStorage.setItem('_year', action.year)
      return { ...state, batchYear: action.year }
    default:
      return state
  }
}
