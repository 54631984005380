import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import dayjs from 'dayjs'

const DateInputPicker = ({ control, name, placeholder, required, allowed, readOnly, isFloating, format }) => {
  const validations = useRef()
  const tabIndex = readOnly ? -1 : null

  useEffect(() => {
    switch (allowed) {
      case 'past':
        validations.current = { pastDate: (v) => dayjs(v) < dayjs() || `${placeholder} should not be a future date` }
        break
      case 'future':
        validations.current = { futureDate: (v) => dayjs(v) > dayjs() || `${placeholder} should not be a past date` }
        break
      default:
        break
    }
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) =>
        isFloating ? (
          <div className="form-floating input-date">
            <Flatpickr
              id={name}
              data-enable-time
              className="form-control"
              placeholder="MM/DD/YYYY"
              autoComplete="off"
              options={{ dateFormat: 'm/d/Y', allowInput: true, enableTime: false }}
              onChange={(selectedDates, dateStr, instance) => onChange(selectedDates.length > 0 ? dayjs(selectedDates[0]).format(format) : '')}
              value={value && new Date(value).getFullYear() > 1 ? new Date(value) : ''}
              tabIndex={tabIndex}
            />
            {value && new Date(value).getFullYear() > 1 && (
              <button type="button" className="btn-close btn-sm" onClick={() => onChange('')} title="Clear date"></button>
            )}
            <label htmlFor={name}>
              {placeholder} {required && <span className="text-danger">*</span>}
            </label>
          </div>
        ) : (
          <Flatpickr
            data-enable-time
            className="form-control"
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            options={{ dateFormat: 'm/d/Y', allowInput: true, enableTime: false }}
            onChange={(selectedDates, dateStr, instance) => onChange(selectedDates.length > 0 ? dayjs(selectedDates[0]).format() : '')}
            value={value && new Date(value).getFullYear() > 1 ? new Date(value) : ''}
          />
        )
      }
      rules={{
        required: required ? (required === true ? `Please enter ${placeholder?.toLowerCase()}` : required) : null,
        validate: validations.current,
      }}
    />
  )
}

DateInputPicker.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  allowed: PropTypes.string,
  readOnly: PropTypes.bool,
  isFloating: PropTypes.bool,
  format: PropTypes.string,
}

export default DateInputPicker
