import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

let alerts = { show: () => {} }
const MySwal = withReactContent(Swal)

alerts.showOk = (content, onOk) => {
  MySwal.fire({
    html: <h5>{content}</h5>,
    icon: 'success',
    heightAuto: false,
    confirmButtonText: 'OK',
  }).then((result) => {
    if (onOk && result.isConfirmed) {
      onOk()
    }
  })
}

alerts.showError = (content, onClose) => {
  MySwal.fire({
    html: <h5>{content}</h5>,
    icon: 'error',
    width: 350,
    heightAuto: false,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: 'CLOSE',
  }).then((result) => {
    if (onClose && result.isDismissed) {
      onClose()
    }
  })
}

alerts.showConfirm = (content, onConfirm, confirmText) => {
  MySwal.fire({
    //title: 'Do you want to save the changes?',
    html: <h5>{content}</h5>,
    icon: 'warning',
    width: 350,
    heightAuto: false,
    reverseButtons: true,
    showConfirmButton: true,
    confirmButtonText: confirmText ? confirmText : 'CONFIRM',
    showCancelButton: true,
    cancelButtonText: 'CANCEL',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      onConfirm()
    }
  })
}

export { alerts }
