import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const TextArea = ({ control, name, className, placeholder, rows, maxLength, required, readOnly, disabled }) => {
  const tabIndex = readOnly ? -1 : null

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <textarea
          id={name}
          type="text"
          className={'form-control ' + (className ? className : '')}
          placeholder={placeholder}
          pattern=".*\S+.*"
          rows={rows}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          value={value || ''}
          onChange={onChange}
          ref={ref}
          tabIndex={tabIndex}
        />
      )}
      rules={{
        required: required ? (required === true ? `Please enter ${placeholder?.toLowerCase()}` : required) : null,
      }}
    />
  )
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default TextArea
