import React from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import PortalContainer from './PortalContainer'

const PortalRouteWrapper = ({ role, children }) => {
  const { authState } = useAuthContext()

  if (!role || role.find((r) => r === authState.user.role)) {
    return children
  }

  return (
    <PortalContainer title="Forbidden">
      <h3 className="text-danger fw-light">You do not have permission to view this page</h3>
    </PortalContainer>
  )
}

export default PortalRouteWrapper
