export async function handleResponse(response) {
  //if (response.ok) return response.json()
  if (response?.status === 200) return response.data
  if (response?.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text()
    throw new Error(error)
  }
  throw new Error('Network response was not ok.')
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console

  // If error is about cancelled request, return custom error and check in catch block the .response
  if (error?.code === 'ERR_CANCELED') throw new CancelledError()
  if (error?.code === 'ERR_NETWORK') throw new NetworkError()

  throw error
}

class CancelledError extends Error {
  constructor(message) {
    super(message)
    this.name = 'CancelledError'
  }
}

class NetworkError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NetworkError'
  }
}
