import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { dateToString } from '../config/lookup'

import logo from '../assets/brands/wcopa-logo-white-192.png'

const PortalContainer = ({ title, back, children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="portal-banner">
        <div className="container-fluid">
          <div className="d-flex py-2">
            <div className="d-flex flex-column justify-content-between">
              <h6 className="fst-italic fw-light text-white">{dateToString(new Date())}</h6>
              <h1 className="h3 text-uppercase text-white mb-0">
                {back && (
                  <Link to={back}>
                    <i className="bi bi-chevron-left text-white"></i>
                  </Link>
                )}
                {title}
              </h1>
            </div>
            <img className="portal-banner__logo" src={logo} alt="" />
          </div>
        </div>
      </div>
      <main id="portal-main" role="main">
        {children}
      </main>
    </React.Fragment>
  )
}

PortalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default PortalContainer
