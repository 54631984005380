import * as apiRoutes from '../config/apiRoutes'
import xhr from './xhr'
import { handleResponse, handleError } from './apiUtils'

// Get own profile
export function loadProfile(id) {
  const url = apiRoutes.GET_PROFILE_API_URL.replace('{id}', id)
  return xhr.get(url).then(handleResponse).catch(handleError)
}

// Update own profile
export function updateProfile(data) {
  return xhr.post(apiRoutes.UPDATE_PROFILE_API_URL, data).then(handleResponse).catch(handleError)
}

export function uploadAvatar(data) {
  let formData = new FormData()
  for (var key in data) {
    const property = key
    if (data[key]) {
      if (Object.prototype.toString.call(data[key]) === '[object Array]') {
        data[key].forEach((s) => {
          formData.append(property, s)
        })
      } else formData.append(property, data[key])
    }
  }
  return xhr.post(apiRoutes.UPLOAD_AVATAR_API_URL, formData).then(handleResponse).catch(handleError)
}

export function register(data) {
  return xhr.post(apiRoutes.REGISTER_PROFILE_API_URL, data).then(handleResponse).catch(handleError)
}
