import React from 'react'
import roles from './role'

// Lazy load components

const Home = React.lazy(() => import('../components/Home'))
const Login = React.lazy(() => import('../components/Account/Login'))
const PrivacyPolicy = React.lazy(() => import('../components/PrivacyPolicy'))
const ForgotPassword = React.lazy(() => import('../components/Account/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../components/Account/ResetPassword'))
const Landing = React.lazy(() => import('../components/Landing'))
const Register = React.lazy(() => import('../components/Account/Register'))

// PORTAL ROUTES
const Dashboard = React.lazy(() => import('../components/Dashboard/Index'))
const Users = React.lazy(() => import('../components/Users/Index'))
const UserProfile = React.lazy(() => import('../components/Users/Profile'))
const UserEdit = React.lazy(() => import('../components/Users/Edit'))
const InviteUser = React.lazy(() => import('../components/Users/Invite'))
const UserPayments = React.lazy(() => import('../components/Users/Payments'))
const UserDocuments = React.lazy(() => import('../components/Users/Documents'))

const Newsletters = React.lazy(() => import('../components/Newsletters/Index'))
const Payments = React.lazy(() => import('../components/Payments/Index'))
const Documents = React.lazy(() => import('../components/Documents/Index'))
//const Events = React.lazy(() => import('../components/Events/Index'))
const Provinces = React.lazy(() => import('../components/Provinces/Index'))
const Profile = React.lazy(() => import('../components/Profile/Index'))
const EditProfile = React.lazy(() => import('../components/Profile/Edit'))
const Settings = React.lazy(() => import('../components/Settings'))
const ChangePassword = React.lazy(() => import('../components/Account/ChangePassword'))
const CostToSponsor = React.lazy(() => import('../components/CostToSponsor/Index'))

const layoutRoutes = [
  { path: '/home', exact: true, component: Home },
  { path: '/terms-and-conditions', exact: true, component: Home },
  { path: '/privacy-policy', exact: true, component: PrivacyPolicy },
]

const plainLayoutRoutes = [
  { path: '/', exact: true, component: Landing },
  { path: '/login', exact: true, component: Login },
  { path: '/forgot-password', exact: true, component: ForgotPassword },
  { path: '/reset-password', exact: true, component: ResetPassword },
  { path: '/register/:id', exact: true, component: Register },
]

const portalRoutes = [
  { path: 'users', exact: true, component: Users, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/:id', exact: true, component: UserProfile, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/edit/:id', exact: true, component: UserEdit, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/invite', exact: true, component: InviteUser, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/eligible', exact: true, component: Users, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/delegate', exact: true, component: Users, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/visa', exact: true, component: Users, role: roles.ADMIN_AND_OFFICERS },
  { path: 'users/companion', exact: true, component: Users, role: roles.ADMIN_AND_OFFICERS },

  { path: 'newsletters', exact: true, component: Newsletters },
  { path: 'provinces', exact: true, component: Provinces, role: roles.ADMIN },

  { path: 'payments/:id', exact: true, component: UserPayments, role: roles.ADMIN_AND_OFFICERS },
  { path: 'documents/:id', exact: true, component: UserDocuments, role: roles.ADMIN_AND_OFFICERS },
  { path: 'payments', exact: true, component: Payments },
  { path: 'documents', exact: true, component: Documents, role: roles.PASSERS },
  // { path: 'events', exact: true, component: Events, role: roles.PASSER },

  { path: 'profile', exact: true, component: Profile },
  { path: 'profile/edit', exact: true, component: EditProfile },

  { path: 'settings', exact: true, component: Settings },
  { path: 'settings/change-password', exact: true, component: ChangePassword },

  //{ path: 'groupmember/:id', exact: true, component: GroupMember },

  { path: 'cost-to-sponsor', exact: true, component: CostToSponsor, role: roles.ADMIN },

  { index: true, exact: true, component: Dashboard },
]

export { layoutRoutes, plainLayoutRoutes, portalRoutes }
