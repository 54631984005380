import React from 'react'
import PropTypes from 'prop-types'
import * as ReactBootstrap from 'react-bootstrap'

const Button = ({ id, type, children, variant, rounded, className, onClick, loading, disabled, form }) => {
  return (
    <ReactBootstrap.Button
      id={id}
      type={type ? type : 'submit'}
      variant={variant}
      className={'btn-loader' + (className ? ` ${className}` : '') + (rounded ? ` rounded-pill` : '')}
      onClick={onClick}
      disabled={disabled}
      form={form}
    >
      {loading && (
        <div className="btn-loader__overlay">
          <div className="btn-loader__wrapper">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      <span className="btn-loader__content">{children}</span>
    </ReactBootstrap.Button>
  )
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Button
