import React, { useMemo, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import sidebarMenu from '../config/sidebarMenu'
import { useAuthContext } from '../contexts/AuthContext'
import { alerts } from '../shared/alerts'
import roleNames from '../config/roleNames'
import { logoutSuccess } from '../actions/authActions'

import logo from '../assets/brands/wcopa-logo-white-192.png'
import roles from '../config/role'

const PortalHeader = () => {
  const { authState, dispatch } = useAuthContext()

  const sidebarItems = useMemo(() => {
    return sidebarMenu.filter((s) => !s.roles || s.roles.includes(authState.user.role))
  }, [authState.user.role])

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const toggleSidebar = () => {
    setShow(false)
  }

  const showLogout = () => {
    alerts.showConfirm('Are you sure you want to logout?', handleLogout, 'LOGOUT')
    toggleSidebar()
  }

  const handleLogout = () => {
    // Call logout action here
    dispatch(logoutSuccess())
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <nav className="portal__header-profile navbar navbar-dark navbar-expand-md">
          <div className="text-start text-md-center mx-3">
            <Link to="/portal/profile/edit" className="text-decoration-none">
              <h4 className="fw-light text-white my-1">
                {authState?.user.firstName} {authState?.user.lastName}
              </h4>
            </Link>
            <div className="d-flex flex-md-column">
              <h6 className="fw-light fst-italic text-white small my-1">{authState && roleNames[authState?.user.role]}</h6>
              {authState.user?.role === roles.ISLAND_OFFICER[0] && <h6 className="fw-light text-white small mx-2 my-1">({authState.user?.island})</h6>}
              {(authState.user?.role === roles.PASSER_SOLO[0] || authState.user?.role === roles.PASSER_GROUP[0]) && authState.user.events?.length > 0 && (
                <h6 className="fw-light text-white small mx-2 my-1">({authState.user.events.join(', ')})</h6>
              )}
            </div>
          </div>
          <button className="navbar-toggler border-0 p-0 ms-auto" type="button" onClick={handleShow}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>

      <Offcanvas className="offcanvas-menu" show={show} onHide={handleClose} responsive="md">
        <Offcanvas.Header closeVariant="white" closeButton>
          <div className="d-flex align-items-center">
            <img className="portal-banner__logo" src={logo} alt="" />
            <h4 className="text-white mb-0">WCOPA Philippines</h4>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {sidebarItems.map((s) => (
            <React.Fragment key={s.path}>
              <NavLink end to={s.path} className="offcanvas-menu__nav-link" onClick={toggleSidebar}>
                <i className={`bi bi-${s.icon} me-3`}></i>
                {s.title}
              </NavLink>
              {s.subMenu &&
                s.subMenu.map((sm) => (
                  <NavLink key={sm.path} end to={sm.path} className="offcanvas-menu__nav-link ps-5" onClick={toggleSidebar}>
                    <i className={`bi bi-${sm.icon} me-2`}></i>
                    {sm.title}
                  </NavLink>
                ))}
            </React.Fragment>
          ))}
          <button onClick={showLogout} className="offcanvas-menu__nav-link mt-auto">
            <i className="bi bi-box-arrow-left me-3"></i>Logout
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  )
}

export default PortalHeader
