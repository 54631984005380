import * as apiRoutes from '../config/apiRoutes'
import qs from 'qs'
import axios from 'axios'

const xhr = axios.create({ baseURL: apiRoutes.API_BASE_URL, timeout: 30000 })
const pendingRequest = new Map() //  Request object
let CancelToken = axios.CancelToken

// Add a request interceptor
xhr.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    //  Get request key
    let requestKey = getReqKey(config)

    // //  Determine whether it is a duplicate request
    if (pendingRequest.has(requestKey)) {
      //  It's a repeat request
      removeReqKey(requestKey) //  Cancel
    } else {
      //  Set up cancelToken
      config.cancelToken = new CancelToken(function executor(cancel) {
        pendingRequest.set(requestKey, cancel) // Set up
      })
    }

    const token = '' //JSON.parse(localStorage.getItem('token')) -- Get token from cookie instead
    //config.headers['Authorization'] = token ? 'Bearer ' + token.accessToken : ''
    if (config?.data?.toString() !== '[object FormData]') {
      config.headers['Content-Type'] = 'application/json'
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
xhr.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    let requestKey = getReqKey(response.config)
    removeReqKey(requestKey)

    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // We will just return the error
    return Promise.reject(error)
  },
)

//  Get request key
function getReqKey(config) {
  //  Request mode 、 Request address 、 The string generated by the request parameter is used as the basis for whether to repeat the request
  if (config) {
    const { method, url, params, data } = config //  Deconstruct these parameters
    // GET ---> params POST ---> data
    const requestKey = [method, url, qs.stringify(params), qs.stringify(data)].join('&')
    return requestKey
  }
}

//  Cancel duplicate request
function removeReqKey(key) {
  if (key) {
    const cancelToken = pendingRequest.get(key)
    if (cancelToken) {
      cancelToken(key) //  Cancel a previously sent request
      pendingRequest.delete(key) //  Delete from request object requestKey
    }
  }
}

export default xhr
