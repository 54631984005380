const initialState = {
  site: {
    batchYear: '',
    regions: [],
  },
  auth: {
    user: null,
    redirect: '/portal',
    notifications: { items: [], total: 0, unseen: 0 },
    accessToken: null,
  },
  apiCallsInProgress: 0,
  errors: {},
}

export default initialState
