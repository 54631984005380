import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import siteReducer from '../reducers/siteReducer'
import initialState from '../reducers/initialState'

const SiteContext = React.createContext(initialState.site)
const { Provider } = SiteContext

const useSiteContext = () => {
  return useContext(SiteContext)
}

const year = localStorage.getItem('_year')

const initState = year
  ? { ...initialState.site, batchYear: year }
  : { ...initialState.site, batchYear: dayjs().month() >= 8 && dayjs().month() <= 11 ? dayjs().year() + 1 : dayjs().year() }

const SiteContextProvider = ({ children }) => {
  const [siteState, dispatch] = useReducer((state, action) => siteReducer(state, action), initState)

  return <Provider value={{ siteState, dispatch }}>{children}</Provider>
}

SiteContextProvider.propTypes = {
  children: PropTypes.node,
}

export { SiteContextProvider, useSiteContext }
