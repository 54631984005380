export const dayInAWeek = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
])
export const months = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
])
export const regions = Object.freeze([
  'REGION I',
  'REGION II',
  'REGION III',
  'REGION IV-A',
  'REGION IV-B',
  'REGION V',
  'REGION VI',
  'REGION VII',
  'REGION VIII',
  'REGION IX',
  'REGION X',
  'REGION XI',
  'REGION XII',
  'REGION XIII',
  'NCR',
  'CAR',
  'ARMM',
])
/**
 * Convert date object to YYYY/MM/DD format
 *
 * @date {Object} Date object
 */
export const convertDateObject = (date) => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : `${date.getMonth()}`
  }/${date.getDate() <= 9 ? `0${date.getDate()}` : `${date.getDate()}`}`
}

/**
 * Convert date object to string
 *
 * @date {Object} Date object
 */
export const dateToString = (date) => {
  return `${dayInAWeek[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

/**
 * Convert date object to string without day of the week
 *
 * @date {Object} Date object
 */
export const dateToStringNoDay = (date) => {
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}


