import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

const Layout = () => {
  const refFooter = React.useRef(null)

  return (
    <React.Fragment>
      <main id="public-main" role="main">
        <Outlet context={refFooter} />
      </main>
      <Footer ref={refFooter} />
    </React.Fragment>
  )
}

export default Layout
