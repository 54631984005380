import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { SiteContextProvider } from './contexts/SiteContext'
import { AuthContextProvider } from './contexts/AuthContext'

import './App.scss'

import logo from './assets/brands/wcopa-logo-black-192.png'

const loader = (
  <div className="splash">
    <img src={logo} alt="WCOPA Team Philippines" />
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Suspense fallback={loader}>
      <BrowserRouter>
        <SiteContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </SiteContextProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
