import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const CheckInput = ({ control, name, className, placeholder, required, readOnly, disabled }) => {
  const tabIndex = readOnly ? -1 : null

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <input
          id={name}
          type="checkbox"
          className={'form-check-input ' + (className ? className : '')}
          readOnly={readOnly}
          disabled={disabled}
          checked={value ? value : false}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          tabIndex={tabIndex}
        />
      )}
      rules={{
        required: required ? (required === true ? `Please check ${placeholder?.toLowerCase()}` : required) : null,
      }}
    />
  )
}

CheckInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CheckInput
