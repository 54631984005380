import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { layoutRoutes, plainLayoutRoutes, portalRoutes } from './config/routes'
import { useAuthContext } from './contexts/AuthContext'

import Layout from './layout/Layout'
import PortalLayout from './layout/PortalLayout'
import PlainLayout from './layout/PlainLayout'
import { loginSuccess } from './actions/authActions'
import { loadProfile } from './api/profileApi'
import PortalRouteWrapper from './layout/PortalRouteWrapper'

const Page404 = React.lazy(() => import('./components/Page404'))

function App() {
  const { dispatch } = useAuthContext()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const loggedInUser = localStorage.getItem('accessToken')
    if (loggedInUser) {
      loadProfile(loggedInUser)
        .then((response) => {
          // Use ID as the accessToken for now
          dispatch(loginSuccess(response.message, response.message.id))
          setInitialized(true)
        })
        .catch((error) => {
          if (error.name === 'CancelledError') return
          setInitialized(true)
        })
    } else {
      setInitialized(true)
    }
  }, [dispatch])

  if (!initialized) return null

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/portal" element={<PortalLayout />}>
          {portalRoutes.map((r, index) => (
            <React.Fragment key={index}>
              <Route
                path={r.path}
                index={r.index}
                exact={r.exact}
                element={
                  <PortalRouteWrapper role={r.role}>
                    <r.component />
                  </PortalRouteWrapper>
                }
              />
              {r.routes &&
                r.routes.map((sr, sindex) => (
                  <Route
                    key={sindex}
                    path={r.path + sr.path}
                    exact={sr.exact}
                    element={
                      <PortalRouteWrapper role={sr.role}>
                        <sr.component />
                      </PortalRouteWrapper>
                    }
                  />
                ))}
            </React.Fragment>
          ))}
        </Route>

        <Route element={<Layout />}>
          {layoutRoutes.map((r, index) => (
            <Route key={index} path={r.path} exact={r.exact} element={<r.component />} />
          ))}
        </Route>

        <Route exact element={<PlainLayout />}>
          {plainLayoutRoutes.map((r, index) => (
            <Route key={index} path={r.path} exact={r.exact} element={<r.component />} />
          ))}
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </HelmetProvider>
  )
}

export default App
