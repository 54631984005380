import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const MobileNumberInput = ({ control, name, placeholder, required }) => {
  let validPhoneNumber = false

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <PhoneInput
          defaultCountry="PH"
          international
          //withCountryCallingCode
          placeholder="Mobile Number"
          value={value}
          onChange={onChange}
          maxLength={20}
          //error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
        />
      )}
      //   rules={{
      //     required: required ? `Please enter ${placeholder?.toLowerCase()}` : null,
      //     validate: {
      //       invalidFormat: () => validPhoneNumber || `${placeholder} has invalid format`,
      //     },
      //   }}
      rules={{
        required: required ? `Please enter ${placeholder?.toLowerCase()}` : null,
        validate: {
          invalidFormat: (value) => (value ? isValidPhoneNumber(value) || `${placeholder} has invalid format` : null),
        },
      }}
    />
  )
}

MobileNumberInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default MobileNumberInput
