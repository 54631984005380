import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import { useAuthContext } from '../contexts/AuthContext'
import { logoutSuccess } from '../actions/authActions'

import logo from '../assets/brands/wcopa-logo-white-192.png'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="nav-link dropdown-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </button>
))

const Header = (props) => {
  const { authState, dispatch } = useAuthContext()
  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const handleSelect = (eventKey, event) => {
    if (eventKey === 'logout') {
      dispatch(logoutSuccess())
    } else {
      navigate(eventKey)
    }
  }

  const handleNavClick = (element, path) => {
    if (element) {
      setShow(false)
      setTimeout(() => {
        element.current.scrollIntoView()
      }, 400)
    } else {
      navigate(path)
    }
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top">
        <div className="container-fluid">
          <Link to="/home" className="text-decoration-none">
            <div className="d-flex align-items-center">
              <img className="img-fluid nav-brand-img d-inline-block d-none d-md-block" src={logo} alt="" />
              <h4 className="m-0 fw-bold text-white">
                <span className="text-nowrap d-none d-lg-block">WCOPA TEAM PHILIPPINES</span>
                <span className="text-nowrap d-lg-none">WCOPA PH</span>
              </h4>
            </div>
          </Link>

          <Offcanvas show={show} onHide={() => setShow(false)} scroll responsive="md" className="offcanvas-menu">
            <Offcanvas.Header closeButton closeVariant="white">
              <Offcanvas.Title>
                <img className="nav-brand-img" src={logo} alt="" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="navbar-nav fs-5 ms-md-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavClick(props.refAboutUs, '/home#about-us')}>
                    ABOUT US
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavClick(props.refEvents, '/home#events')}>
                    EVENTS
                  </button>
                </li>
                <li className="nav-item">
                  {authState?.user ? (
                    <Dropdown align="end" onSelect={handleSelect}>
                      <Dropdown.Toggle as={CustomToggle}>
                        Hi, {authState.user.firstName} {authState.user.lastName}!
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="/portal">
                          <i className="bi bi-speedometer2 me-3"></i>
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="/portal/profile">
                          <i className="bi bi-person me-3"></i>
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="logout">
                          <i className="bi bi-box-arrow-left me-3"></i>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Link to="/login" className="nav-link">
                      LOGIN
                    </Link>
                  )}
                </li>
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
          <button className="navbar-toggler border-0 p-0" type="button" onClick={() => setShow(true)}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </header>
  )
}
export default Header
