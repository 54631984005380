import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const EmailInput = ({ control, className, name, placeholder, required, readOnly, disabled, autoFocus }) => {
  const tabIndex = readOnly ? -1 : null
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <input
          id={name}
          type="email"
          className={'form-control' + (className ? ' ' + className : '')}
          placeholder={placeholder}
          maxLength={100}
          readOnly={readOnly}
          disabled={disabled}
          value={value || ''}
          onChange={onChange}
          ref={ref}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
        />
      )}
      rules={{
        required: required ? (required === true ? `Please enter ${placeholder?.toLowerCase()}` : required) : null,
        pattern: {
          value: /^([a-zA-Z0-9]+(?:[.-_]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,7})$/i,
          message: 'Email address has invalid format',
        },
      }}
    />
  )
}

EmailInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default EmailInput
