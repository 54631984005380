import React from 'react'
import { Outlet } from 'react-router-dom'

const PlainLayout = () => {
  return (
    <div className="full-content">
      <Outlet />
    </div>
  )
}

export default PlainLayout
