const roles = Object.freeze({
  ADMIN: ['ADMIN'],
  ISLAND_OFFICER: ['ISLAND_OFFICER'],
  PROVINCIAL_OFFICER: ['PROVINCIAL_OFFICER'],
  PASSER_SOLO: ['PASSER_SOLO'],
  PASSER_GROUP: ['PASSER_GROUP'],

  ADMIN_AND_OFFICERS: ['ADMIN', 'ISLAND_OFFICER', 'PROVINCIAL_OFFICER'],
  OFFICERS: ['ISLAND_OFFICER', 'PROVINCIAL_OFFICER'],
  PASSERS: ['PASSER_SOLO', 'PASSER_GROUP'],
})

export default roles
