import React, { Suspense } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PortalHeader from './PortalHeader'
import { useAuthContext } from '../contexts/AuthContext'

const spinner = (
  <div className="spinner-border">
    <span className="visually-hidden">Loading...</span>
  </div>
)

const progress = (
  <div className="progress rounded-0">
    <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100" role="progressbar"></div>
  </div>
)

const PortalLayout = () => {
  const { authState } = useAuthContext()
  const location = useLocation()

  if (!authState.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const search = authState.redirect ? `?returnUrl=${location.pathname + location.search}` : null
    return <Navigate to={{ pathname: '/login', search: search }} state={{ from: location }} replace />
  }

  return (
    <div className="portal">
      {/* Header and Sidebar */}
      <div className="portal__header">
        <PortalHeader />
      </div>
      <div className="portal__content">
        {/* Main content */}
        <Suspense fallback={progress}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

export default PortalLayout
