import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const FileInput = ({ control, name, className, placeholder, required, readOnly, disabled, accept }) => {
  let patterns = useRef()
  const tabIndex = readOnly ? -1 : null

  // const handleChange = (event) => {
  //   const fileUploaded = event.target.files[0]
  //   PropTypes.handleFile(fileUploaded)
  // }

  //const

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <input
          id={name}
          type="file"
          className={'form-control ' + (className ? className : '')}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          accept={accept}
          onChange={(e) => {
            onChange(e.target.files[0])
            //setFileName(e.target.files.length ? e.target.files[0].name : '')
          }}
          ref={ref}
          tabIndex={tabIndex}
        />
      )}
      rules={{
        required: required ? (required === true ? `Please select ${placeholder?.toLowerCase()}` : required) : null,
        pattern: patterns.current,
      }}
    />
  )
}

FileInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  //onChange: PropTypes.func.isRequired,
}

export default FileInput
