import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { alerts } from '../shared/alerts'
import { Button, EmailInput } from '../components/_forms'

const Footer = React.forwardRef((props, ref) => {
  //const { label, ...otherProps } = props
  const {
    control,
    setError,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)

    alerts.showOk('Subscription added. Please check your email for future news and updates')
    reset()
  }

  return (
    <footer id="footer" className="py-4" ref={ref}>
      <div className="container-xl">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="text-center text-md-start mb-3">
              <h5 className="text-white">CONNECT WITH US</h5>
              <a className="me-2" href="https://www.facebook.com/wcopateamph/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="fs-3 me-2 text-white" icon={faFacebookF} />
              </a>
              <a className="me-2" href="https://twitter.com/officialwcopa" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="fs-3 me-2 text-white" icon={faTwitter} />
              </a>
              <a className="me-2" href="https://instagram.com/wcopateamphilippines?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="fs-3 me-2 text-white" icon={faInstagram} />
              </a>
              <a href="https://youtube.com/user/wcopacom" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="fs-3 text-white" icon={faYoutube} />
              </a>
            </div>
            <div className="text-white text-center text-md-start mb-3">
              Email us at: <span className="text-decoration-underline">inquiries@tpwcopa.com</span>
            </div>
          </div>
          {/* <div className="col-12 col-md-6 mt-3 mt-md-0 text-white">
            <div className="mb-3">
              <h5 className="nav-newsletter text-white">NEWSLETTER</h5>
              <p className="fw-light mb-1">Subscribe to our newsletter to get latest updates</p>
              <form id="formUser" noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                  <EmailInput
                    id="emailAddress"
                    control={control}
                    name="emailAddress"
                    className={errors?.emailAddress ? 'is-invalid' : ''}
                    placeholder="Enter your email address.."
                    required
                  />
                  <Button id="btnSubmit" className="btn btn-outline-secondary text-white">
                    SUBSCRIBE
                  </Button>
                </div>
              </form>
            </div>
          </div> */}
        </div>
        <hr className="text-white my-2" />
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <div className="text-white text-center my-1">Copyright © TPWCOPA 2022</div>
          <div className="d-flex align-items-center justify-content-center my-1">
            <Link to="/privacy-policy" className="text-decoration-none text-white">
              Privacy Policy
            </Link>
            {/* <span className="vr text-white mx-3"></span>
            <Link to="/terms-and-conditions" className="text-decoration-none text-white">
              Terms &amp; Conditions
            </Link> */}
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
