import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const SelectInput = ({ control, name, className, items, placeholder, required, readOnly, disabled }) => {
  const tabIndex = readOnly ? -1 : null

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <select
          id={name}
          className={'form-select ' + (className ? className : '')}
          name={name}
          readOnly={readOnly}
          value={value || ''}
          onChange={onChange}
          ref={ref}
          tabIndex={tabIndex}
        >
          <option value="" className="text-muted">
            [Select {placeholder}]
          </option>
          {items &&
            items.map((i, index) => (
              <option value={i.value} key={index}>
                {i.text}
              </option>
            ))}
        </select>
      )}
      rules={{
        required: required ? (required === true ? `Please select ${placeholder?.toLowerCase()}` : required) : null,
      }}
    />
  )
}

SelectInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default SelectInput
