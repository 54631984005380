import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const TextInput = ({ control, name, className, placeholder, maxLength, required, allowed, readOnly, disabled }) => {
  let patterns = useRef()
  const tabIndex = readOnly ? -1 : null

  useEffect(() => {
    switch (allowed) {
      case 'LettersNumbers':
        patterns.current = { value: /^[A-Za-z0-9]+$/i, message: 'Only letters and numbers are allowed' }
        break
      case 'LettersOnly':
        patterns.current = { value: /^[A-Za-z ]+$/i, message: 'Only letters and spaces are allowed' }
        break
      case 'NumbersOnly':
        patterns.current = { value: /^[0-9]+$/i, message: 'Only numbers are allowed' }
        break
      default:
        break
    }
  }, [allowed])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <input
          id={name}
          type="text"
          className={'form-control ' + (className ? className : '')}
          placeholder={placeholder}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          value={value || ''}
          onChange={(e) => {
            if (allowed === 'NumbersOnly') {
              const re = /^[0-9]+$/
              if (!e.target.value || re.test(e.target.value)) {
                onChange(e.target.value)
              }
            } else onChange(e)
          }}
          ref={ref}
          tabIndex={tabIndex}
        />
      )}
      rules={{
        required: required ? (required === true ? `Please enter ${placeholder?.toLowerCase()}` : required) : null,
        pattern: patterns.current,
      }}
    />
  )
}

TextInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  allowed: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default TextInput
