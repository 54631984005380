import * as types from '../actions/actionTypes'

export default function authReducer(state, action) {
  switch (action.type) {
    case types.LOGIN:
      localStorage.setItem('accessToken', action.accessToken)
      const redirect = action.redirect ? action.redirect : '/portal'
      //const user = !action.user.roles ? { ...action.user, role: 'ADMIN' } : action.user
      return { ...state, user: action.user, accessToken: action.accessToken, redirect }
    case types.LOGOUT:
      localStorage.removeItem('accessToken')
      return { ...state, user: null, accessToken: null, redirect: '' }
    case types.UPDATE_USER:
      return { ...state, user: action.user }
    case types.UPDATE_AVATAR:
      return { ...state, user: { ...state.user, imagePath: action.path } }
    case types.UPDATE_EVENTS:
      return { ...state, user: { ...state.user, events: action.events } }
    default:
      return state
  }
}
