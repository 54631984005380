// Authentication
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// Profile
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
export const UPDATE_EVENTS = 'UPDATE_EVENTS'

// Notifications
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS'
export const LOAD_NOTIFICATIONS_COUNT_SUCCESS = 'LOAD_NOTIFICATIONS_COUNT_SUCCESS'
export const RESET_NOTIFICATIONS_SUCCESS = 'RESET_NOTIFICATIONS_SUCCESS'

// Site
export const SET_DEVICE = 'SET_DEVICE'
export const SET_SHOW_OFFCANVAS = 'SET_SHOW_OFFCANVAS'
export const SET_SHOW_SIDEBAR = 'SET_SHOW_SIDEBAR'
export const SET_REGIONS = 'SET_REGIONS'
export const SET_BATCH_YEAR = 'SET_BATCH_YEAR'

export const BEGIN_API_CALL = 'BEGIN_API_CALL'
export const END_API_CALL = 'END_API_CALL'
export const API_CALL_ERROR = 'API_CALL_ERROR'
