import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import authReducer from '../reducers/authReducer'
import initialState from '../reducers/initialState'

const AuthContext = React.createContext(initialState.auth)
const { Provider } = AuthContext

const useAuthContext = () => {
  return useContext(AuthContext)
}

const AuthContextProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(
    (state, action) => authReducer(state, action),
    initialState.auth,
  )

  useEffect(() => {}, [])

  return <Provider value={{ authState, dispatch }}>{children}</Provider>
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
}

export { AuthContextProvider, useAuthContext }
