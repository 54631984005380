import roles from './role'

const userMenu = Object.freeze([{ path: '/portal/users/invite', title: 'Invite User', icon: 'plus-circle', roles: roles.ADMIN_AND_OFFICERS }])

const sidebarMenu = Object.freeze([
  { path: '/portal', title: 'Dashboard', icon: 'speedometer2' },
  { path: '/portal/users', title: 'Users', icon: 'people', roles: roles.ADMIN_AND_OFFICERS, subMenu: userMenu },
  //{ path: '/portal/passers', title: 'Auditionees', icon: 'people', roles: roles.ADMIN_AND_OFFICERS },
  //{ path: '/portal/newsletters', title: 'Newsletter', icon: 'newspaper', roles: roles.ADMIN },
  { path: '/portal/documents', title: 'Documents', icon: 'files', roles: roles.PASSERS },
  { path: '/portal/payments', title: 'Payments', icon: 'wallet', roles: roles.PASSERS },
  //{ path: '/portal/events', title: 'Events', icon: 'calendar-event', roles: roles.PASSERS },
  //{ path: '/portal/provinces', title: 'Provinces', icon: 'map', roles: ['ADMIN'] },
  { path: '/portal/profile', title: 'Profile', icon: 'person' },
  { path: '/portal/settings', title: 'Settings', icon: 'gear' },
])

export default sidebarMenu
